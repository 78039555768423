
import { Button } from "@/Components/ui/button";
import { Card, CardContent } from "@/Components/ui/card";
import { Separator } from "@/Components/ui/separator";
import { ucwords } from "@/Hooks/Functions";
import ProductColorsLine from "@/Modules/ProductColorsLine";
import { Icon } from "@iconify/react";
import { Link } from "@inertiajs/react";
import { useRef, useState } from "react";
import Carousel from "react-multi-carousel";


const EachPredesignProduct = ({ item }) => {
    const ref = useRef(null);
    const [color, setColor] = useState(!item.use_custom_mockup ? item?.colors?.[0]?.hex : "#fff");
    const responsive = {
        desktop: {
            breakpoint: { max: 5000, min: 0 },
            items: 1
        }
    };
    const setActive = (hex) => {
        if (item.use_custom_mockup) {
            let index = item?.colors.findIndex((color) => color.hex === hex);
            if (~index) {
                setColor("#ffffff");
                ref.current.goToSlide(index)
            }
        } else {
            setColor(hex);
        }
    }
    return (
        <Card className="group flex flex-col overflow-hidden">
            <Carousel ref={ref} containerClass="w-full" responsive={responsive} removeArrowOnDeviceType={["desktop"]}>
                {item?.previews?.map((image) => (
                    <img key={image.id} src={image?.url_inline} style={{ backgroundColor: color }} className="w-full aspect-auto object-fill h-[275px]" />
                ))}
            </Carousel>
            <Separator />
            <CardContent className="w-full flex p-2 relative flex-col gap-2">
                <div className="w-full gap-2 font-semibold flex flex-row justify-between items-center">
                    <small className="hover:underline text-center text-xs truncate whitespace-nowrap">{ucwords(item.name)}</small>
                    <small className=" text-xs whitespace-nowrap">{item.range_price}</small>
                </div>
                <div className="py-2 flex gap-1 w-full justify-center items-center flex-row flex-nowrap">
                    <ProductColorsLine items={item?.colors} onColorSelect={(hex) => setActive(hex)} />
                </div>
                <Link className="w-full" href={route('predesign-products.view', { uuid: item.uuid })}>
                    <Button className="w-full" size="sm">Place Order</Button>
                </Link>
            </CardContent>
        </Card>
    )
}

export default function BuyFinishProductBlock({ predesignProducts }) {
    return (
        <div className="w-full flex bg-muted-foreground/10 flex-col justify-center items-center py-4">
            <div className="w-full max-w-7xl flex flex-col pt-12 pb-6">
                <div className="flex flex-row justify-between md:py-6 py-2 md:px-0 px-4 items-center w-full">
                    <h1 data-aos="fade-down" className="md:text-3xl text-lg mb-4 text-center font-semibold font-nunito italic text-primary uppercase">
                        Buy Predesign Products
                    </h1>
                    <Link href={route("home.predesign-products")}>
                        <Button variant="theme" size="sm">
                            <span className="md:inline-flex hidden">View More Product</span>
                            <Icon icon="solar:double-alt-arrow-right-broken" width="30" height="30" />
                        </Button>
                    </Link>
                </div>
                <div className="grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-2 items-stretch grid-cols-1 gap-2">
                    {predesignProducts?.map((predesignProduct, index) => <div key={index} className="!col-span-1">
                        <EachPredesignProduct item={predesignProduct} />
                    </div>
                    )}
                </div>
            </div>
        </div>
    )
}
